import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["country", "cities", "country_name", "city_name", "districts", "shop_country_code"];
  static values = {
    url: String,
    selected: String,
    all: String,
    prefix: String
  };

  connect() {
    if ($('#user_city_code').length !== 0) {
      if ($('#user_city_code').attr('multiple')) {
        $('#user_city_code').chosen({
            allow_single_deselect: true,
            width: '100%',
            multiple: true
        }).change(() => {
          this.change_city();
        })
      }
    }

    if ($('#user_district').length !== 0) {
      if ($('#user_district').attr('multiple')) {
        $('#user_district').chosen({
            allow_single_deselect: true,
            width: '100%',
            multiple: true
        });
      }
    }

    if (this.selectValue().length > 0) {
      if($('.selected_country'+this.prefixValue).length > 0){
        $('.country_code'+this.prefixValue).val($('.selected_country'+this.prefixValue).val());
        $('.shop_country_code'+this.prefixValue).val($('.selected_country'+this.prefixValue).val());
      }
      Promise.resolve().then(() => {
        this.fetch().then(() => {
          this.fetchDistricts(); 
        });
      });
    }
  }

  change() {
    $('.country_name'+this.prefixValue).val(this.selectName());
    $('.shop_country_code'+this.prefixValue).val(this.selectName());
    $('.city_name'+this.prefixValue).val("");
    $('.district-code'+this.prefixValue).val("");
    $('.selected_district'+this.prefixValue).val("");
    this.fetch();
  }

  change_city() {
    $('.city_name'+this.prefixValue).val(this.selectCityName());
    this.fetchDistricts();
  }

  fetchDistricts() {
    const selectedCityCode = Array.isArray($('.city_code'+this.prefixValue).val()) ? $('.city_code'+this.prefixValue).val().join(',') : $('.city_code'+this.prefixValue).val();
    const locale = $('.city_code'+this.prefixValue).data('locale') ? $('.city_code'+this.prefixValue).data('locale') : 'en';
    const exclude_all_districts = $('.district-code'+this.prefixValue).data("exclude-all-districts");

    fetch('/' + locale + '/vatrenas/districts_json?city_code=' + encodeURIComponent(selectedCityCode) + '&exclude_all_districts=' + exclude_all_districts, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.text()) 
      .then((html) => {
        this.insertDistricts(html);
        this.selectDistrict();
      });
  }

  selectDistrict() {
    const selectedDistrictValue = $(".selected_district"+this.prefixValue).val();
    if (selectedDistrictValue) {
      if($('.district-for-districts').length > 0) {
        $('.district-for-districts').val(selectedDistrictValue.split(','));
        $('.district-for-districts').trigger("chosen:updated");
      } else {
        this.districtsTarget.value = selectedDistrictValue;
      }
    }
  }

  insertDistricts(html) {  
    this.districtsTarget.innerHTML = ''; // Clear existing options 
    this.districtsTarget.insertAdjacentHTML('beforeend', html);
    $('.district-code'+this.prefixValue).trigger("chosen:updated");
    $('.district-code'+this.prefixValue).trigger("liszt:updated");
  }

  selectCityName() {
    return this.citiesTarget.selectedOptions.length > 0 ? this.citiesTarget.selectedOptions[0].text : "";
  }

  selectName() {
    return this.countryTarget.selectedOptions[0].text;
  }

  selectValue() {
    return this.countryTarget.selectedOptions[0].value;
  }

  fetch() {
    return fetch(`${this.urlValue}?${this.params()}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => this.insertData(this, html));
  }

  insertData(obj, html) {
    obj.citiesTarget.innerHTML = "";
    obj.citiesTarget.insertAdjacentHTML('beforeend', html);

    $('.city_code'+this.prefixValue).trigger("chosen:updated");
    $('.city_code'+this.prefixValue).trigger("liszt:updated");

    if ($(".selected_city"+this.prefixValue).length > 0) {
      $('.city_code'+this.prefixValue).val($('.city_code').attr('multiple') ? $(".selected_city"+this.prefixValue).val().split(',') : $(".selected_city"+this.prefixValue).val());
      $('.city_code').trigger("chosen:updated");
    } else {
      select_city();
    }
  }

  params() {
    let params = new URLSearchParams();
    params.append('id', this.selectValue());
    params.append('select', this.citiesTarget.id);
    params.append('show_all', this.allValue);
    return params;
  }

  paramsForDistricts() {
    let params = new URLSearchParams();
    params.append('id', this.selectValue());
    return params;
  }
}
